/**
 * English translate
 * @type Object
 */
const en = {
  btn: {
    about: 'About',
    back: 'Back',
    cancel: 'Cancel',
    clear: 'Clear',
    copy: 'Copy',
    cut: 'Cut',
    delete: 'Delete',
    edit: 'Edit',
    forward: 'Forward',
    folder: 'New folder',
    file: 'New file',
    fullScreen: 'Full screen',
    grid: 'Grid',
    paste: 'Paste',
    refresh: 'Refresh',
    submit: 'Submit',
    table: 'Table',
    upload: 'Upload',
    uploadSelect: 'Select files',
    hidden: ' Hidden files',
    cropThumb: 'Crop thumbnails',
  },
  clipboard: {
    actionType: 'Type',
    copy: 'Copy',
    cut: 'Cut',
    none: 'Nothing selected',
    title: 'Clipboard',
  },
  contextMenu: {
    copy: 'Copy',
    cut: 'Cut',
    delete: 'Delete',
    download: 'Download',
    info: 'Selected:',
    open: 'Open',
    paste: 'Paste',
    properties: 'Properties',
    rename: 'Rename',
    select: 'Select',
    view: 'View',
    zip: 'Zip',
    unzip: 'Unzip',
    edit: 'Edit',
    audioPlay: 'Play',
    videoPlay: 'Play',
  },
  info: {
    directories: 'Folders:',
    files: 'Files:',
    selected: 'Selected:',
    selectedSize: 'Files size:',
    size: 'Files size:',
  },
  manager: {
    table: {
      date: 'Date',
      folder: 'Folder',
      name: 'Name',
      size: 'Size',
      type: 'Type',
      width: 'Width',
      height: 'Height',
    },
  },
  modal: {
    about: {
      developer: 'Developer',
      name: 'Laravel File Manager',
      title: 'About',
      version: 'Version',
    },
    delete: {
      noSelected: 'Nothing selected!',
      title: 'Delete',
    },
    newFile: {
      fieldName: 'File name',
      fieldFeedback: 'File exists!',
      title: 'Create new file',
    },
    newFolder: {
      fieldName: 'Folder name',
      fieldFeedback: 'Folder exists!',
      title: 'Create new folder',
    },
    preview: {
      title: 'Preview',
    },
    properties: {
      disk: 'Disk',
      modified: 'Modified',
      name: 'Name',
      path: 'Path',
      size: 'Size',
      title: 'Properties',
      type: 'Type',
      url: 'URL',
      access: 'Access',
      access_0: 'Access denied',
      access_1: 'Only Read',
      access_2: 'Read and Write',
    },
    rename: {
      directoryExist: 'Directory exists',
      fieldName: 'Enter new name',
      fieldFeedback: 'Invalid name',
      fileExist: 'File exists',
      title: 'Rename',
    },
    status: {
      noErrors: 'No errors!',
      title: 'Status',
    },
    upload: {
      ifExist: 'If file exist:',
      noSelected: 'No files selected!',
      overwrite: 'Overwrite!',
      selected: 'Selected:',
      size: 'Size:',
      skip: 'Skip',
      title: 'Upload files',
    },
    editor: {
      title: 'Editor',
    },
    audioPlayer: {
      title: 'Audio player',
    },
    videoPlayer: {
      title: 'Video player',
    },
    zip: {
      title: 'Create archive',
      fieldName: 'Archive name',
      fieldFeedback: 'Archive exists!',
    },
    unzip: {
      title: 'Unpack archive',
      fieldName: 'Folder name',
      fieldRadioName: 'Extract to:',
      fieldRadio1: 'To current folder',
      fieldRadio2: 'In a new folder',
      fieldFeedback: 'Folder exists!',
      warning: 'Attention! If the names match, the files will be overwritten!',
    },
    cropper: {
      title: 'Cropping',
      apply: 'Apply',
      reset: 'Reset',
      save: 'Save',
    },
    thumb: {
      title: 'Thumbnails',
    },
  },
  notifications: {
    cutToClipboard: 'Cut to clipboard!',
    copyToClipboard: 'Copied to clipboard!',
  },
  response: {
    noConfig: 'Config not found!',
    notFound: 'Not found!',
    diskNotFound: 'Disk not found!',
    pathNotFound: 'Path not found!',
    diskSelected: 'Disk selected!',
    // files
    fileExist: 'File already exists!',
    fileCreated: 'File created!',
    fileUpdated: 'File updated!',
    fileNotFound: 'File not found!',
    // directories
    dirExist: 'Directory already exists!',
    dirCreated: 'Directory created!',
    dirNotFound: 'Directory not found',
    // actions
    uploaded: 'All files uploaded!',
    notAllUploaded: 'Some files weren\'t uploaded!',
    delNotFound: 'Some items weren\'t founded!',
    deleted: 'Deleted!',
    renamed: 'Renamed!',
    copied: 'Copied successfully!',
    // zip
    zipError: 'Error creating archive!',
    // acl
    aclError: 'Access denied!',
  },
}

export default en
