export default [
  {
    path: '/dashboard',
    name: 'dashboard-index',
    component: () => import('@/views/dashboard/Analytics.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/info/Home.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/webmaster',
    name: 'webmaster',
    component: () => import('@/views/info/Web.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import('@/views/info/Ref.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/views/info/Reviews.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard-index',
    component: () => import('@/views/admin/dashboard/Analytics.vue'),
    meta: {
      resource: 'admin:dashboard',
      action: 'index',
    },
  },
]
