import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const cart = JSON.parse(localStorage.getItem('cart'))
      return cart ? Object.values(cart).length : 0
    })(),
    cartItems: (() => Object.values(JSON.parse(localStorage.getItem('cart')) || {}))(),
    wishlistItemsCount: (() => {
      const wishlist = JSON.parse(localStorage.getItem('wishlist'))
      return wishlist ? Object.values(wishlist).length : 0
    })(),
    wishlistItems: (() => Object.values(JSON.parse(localStorage.getItem('wishlist')) || {}))(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
    UPDATE_CART_ITEMS(state, cart) {
      state.cartItems = Object.values(cart)
    },
    UPDATE_WISHLIST_ITEMS_COUNT(state, count) {
      state.wishlistItemsCount = count
    },
    UPDATE_WISHLIST_ITEMS(state, wishlist) {
      state.wishlistItems = Object.values(wishlist)
    },
  },
  actions: {
    addProductInWishlist(ctx, { product }) {
      axios.get(`${process.env.VUE_APP_API_URL}/api/promo/products/${product.slug}/favorite`)
      const wishlist = JSON.parse(localStorage.getItem('wishlist')) ?? {}
      Vue.set(wishlist, product.id, { product })
      localStorage.setItem('wishlist', JSON.stringify(wishlist))
      return wishlist
    },
    removeProductFromWishlist(ctx, { productId }) {
      const wishlist = JSON.parse(localStorage.getItem('wishlist')) ?? {}
      delete wishlist[`${productId}`]
      localStorage.setItem('wishlist', JSON.stringify(wishlist))
      return wishlist
    },

    addProductInCart(ctx, { item }) {
      const cart = JSON.parse(localStorage.getItem('cart')) ?? {}
      Vue.set(cart, item.product.id, item)
      localStorage.setItem('cart', JSON.stringify(cart))
      return cart
    },
    removeProductFromCart(ctx, { productId }) {
      const cart = JSON.parse(localStorage.getItem('cart')) ?? {}
      delete cart[`${productId}`]
      localStorage.setItem('cart', JSON.stringify(cart))
      return cart
    },
  },
}
