export default [
  {
    path: '/admin/account/setting',
    name: 'admin:account-setting',
    component: () => import('@/views/admin/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'market.navigation.settings',
      breadcrumb: [
        {
          text: 'market.navigation.system',
        },
        {
          text: 'market.navigation.settings',
          active: true,
        },
      ],
      resource: 'admin:account',
      action: 'index',
    },
  },

  {
    path: '/admin/channels',
    name: 'admin:products-index',
    component: () => import('@/views/admin/products/List.vue'),
    meta: {
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          active: true,
        },
      ],
      resource: 'admin:products',
      action: 'index',
    },
  },
  {
    path: '/admin/channels/create',
    name: 'admin:products-store',
    component: () => import('@/views/admin/products/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          to: { name: 'admin:products-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:products',
      action: 'store',
    },
  },
  {
    path: '/admin/channels/edit/:id',
    name: 'admin:products-update',
    component: () => import('@/views/admin/products/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          to: { name: 'admin:products-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:products',
      action: 'update',
    },
  },

  {
    path: '/admin/channel-stickers',
    name: 'admin:stickers-index',
    component: () => import('@/views/admin/products/stickers/List.vue'),
    meta: {
      pageTitle: 'market.navigation.stickers',
      breadcrumb: [
        {
          text: 'market.navigation.stickers',
          active: true,
        },
      ],
      resource: 'admin:stickers',
      action: 'index',
    },
  },
  {
    path: '/admin/channel-stickers/create',
    name: 'admin:stickers-store',
    component: () => import('@/views/admin/products/stickers/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.stickers',
      breadcrumb: [
        {
          text: 'market.navigation.stickers',
          to: { name: 'admin:stickers-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:stickers',
      action: 'store',
    },
  },
  {
    path: '/admin/channel-stickers/edit/:id',
    name: 'admin:stickers-update',
    component: () => import('@/views/admin/products/stickers/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.stickers',
      breadcrumb: [
        {
          text: 'market.navigation.stickers',
          to: { name: 'admin:stickers-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:stickers',
      action: 'update',
    },
  },

  {
    path: '/admin/channel-categories',
    name: 'admin:product-categories-index',
    component: () => import('@/views/admin/products/categories/List.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'index',
    },
  },
  {
    path: '/admin/channel-categories/create',
    name: 'admin:product-categories-store',
    component: () => import('@/views/admin/products/categories/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:product-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'store',
    },
  },
  {
    path: '/admin/channel-categories/edit/:id',
    name: 'admin:product-categories-update',
    component: () => import('@/views/admin/products/categories/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:product-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'update',
    },
  },

  {
    path: '/admin/channel-reviews',
    name: 'admin:reviews-index',
    component: () => import('@/views/admin/products/reviews/List.vue'),
    meta: {
      pageTitle: 'market.navigation.reviews',
      breadcrumb: [
        {
          text: 'market.navigation.reviews',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'index',
    },
  },
  {
    path: '/admin/channels-reviews/create',
    name: 'admin:reviews-store',
    component: () => import('@/views/admin/products/reviews/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.reviews',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          to: { name: 'admin:reviews-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'show',
    },
  },
  {
    path: '/admin/channels-reviews/edit/:id',
    name: 'admin:reviews-update',
    component: () => import('@/views/admin/products/reviews/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.reviews',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          to: { name: 'admin:reviews-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'show',
    },
  },

  {
    path: '/admin/posts',
    name: 'admin:posts-index',
    component: () => import('@/views/admin/posts/List.vue'),
    meta: {
      pageTitle: 'market.navigation.posts',
      breadcrumb: [
        {
          text: 'market.navigation.posts',
          active: true,
        },
      ],
      resource: 'admin:posts',
      action: 'index',
    },
  },
  {
    path: '/admin/posts/create',
    name: 'admin:posts-store',
    component: () => import('@/views/admin/posts/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.posts',
      breadcrumb: [
        {
          text: 'market.navigation.posts',
          to: { name: 'admin:posts-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:posts',
      action: 'store',
    },
  },
  {
    path: '/admin/posts/edit/:id',
    name: 'admin:posts-update',
    component: () => import('@/views/admin/posts/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.posts',
      breadcrumb: [
        {
          text: 'market.navigation.posts',
          to: { name: 'admin:posts-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:posts',
      action: 'update',
    },
  },

  {
    path: '/admin/post-categories',
    name: 'admin:post-categories-index',
    component: () => import('@/views/admin/posts/categories/List.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'index',
    },
  },
  {
    path: '/admin/post-categories/create',
    name: 'admin:post-categories-store',
    component: () => import('@/views/admin/posts/categories/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:post-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'store',
    },
  },
  {
    path: '/admin/post-categories/edit/:id',
    name: 'admin:post-categories-update',
    component: () => import('@/views/admin/posts/categories/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:post-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'update',
    },
  },

  {
    path: '/admin/navigations',
    name: 'admin:navigations-index',
    component: () => import('@/views/admin/navigations/List.vue'),
    meta: {
      pageTitle: 'market.navigation.navigations',
      breadcrumb: [
        {
          text: 'market.navigation.navigations',
          active: true,
        },
      ],
      resource: 'admin:navigations',
      action: 'index',
    },
  },
  {
    path: '/admin/navigations/create',
    name: 'admin:navigations-store',
    component: () => import('@/views/admin/navigations/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.navigations',
      breadcrumb: [
        {
          text: 'market.navigation.navigations',
          to: { name: 'admin:navigations-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:navigations',
      action: 'store',
    },
  },
  {
    path: '/admin/navigations/edit/:id',
    name: 'admin:navigations-update',
    component: () => import('@/views/admin/navigations/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.navigations',
      breadcrumb: [
        {
          text: 'market.navigation.navigations',
          to: { name: 'admin:navigations-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:navigations',
      action: 'update',
    },
  },

  {
    path: '/admin/navigation-items/:navigation_id/create',
    name: 'admin:navigation-items-store',
    component: () => import('@/views/admin/navigations/items/Create.vue'),
    meta: {
      pageTitle: 'market.navigations.tabs.items.label',
      breadcrumb: [
        {
          text: 'market.navigations.tabs.items.label',
          to: { name: 'admin:navigations-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:navigations',
      action: 'store',
    },
  },
  {
    path: '/admin/navigation-items/:navigation_id/edit/:id',
    name: 'admin:navigation-items-update',
    component: () => import('@/views/admin/navigations/items/Edit.vue'),
    meta: {
      pageTitle: 'market.navigations.tabs.items.label',
      breadcrumb: [
        {
          text: 'market.navigations.tabs.items.label',
          to: { name: 'admin:navigations-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:navigations',
      action: 'update',
    },
  },

  {
    path: '/admin/settings',
    name: 'admin:settings',
    component: () => import('@/views/admin/system/settings/List'),
    meta: {
      pageTitle: 'market.navigation.settings',
      breadcrumb: [
        {
          text: 'market.navigation.system',
        },
        {
          text: 'market.navigation.settings',
          active: true,
        },
      ],
      resource: 'admin:settings',
      action: 'index',
    },
  },
  {
    path: '/admin/languages',
    name: 'admin:languages',
    component: () => import('@/views/admin/system/languages/List'),
    meta: {
      pageTitle: 'market.navigation.languages',
      breadcrumb: [
        {
          text: 'market.navigation.system',
        },
        {
          text: 'market.navigation.languages',
          active: true,
        },
      ],
      resource: 'admin:languages',
      action: 'index',
    },
  },
  {
    path: '/admin/translations',
    name: 'admin:translations',
    component: () => import('@/views/admin/system/translations/List'),
    meta: {
      pageTitle: 'market.navigation.translations',
      breadcrumb: [
        {
          text: 'market.navigation.system',
        },
        {
          text: 'market.navigation.translations',
          active: true,
        },
      ],
      resource: 'admin:translations',
      action: 'index',
    },
  },

  {
    path: '/admin/notification_templates',
    name: 'admin:notification_templates-index',
    component: () => import('@/views/admin/system/notification_templates/List.vue'),
    meta: {
      pageTitle: 'market.navigation.notification_templates',
      breadcrumb: [
        {
          text: 'market.navigation.notification_templates',
          active: true,
        },
      ],
      resource: 'admin:settings',
      action: 'index',
    },
  },
  {
    path: '/admin/notification_templates/create',
    name: 'admin:notification_templates-store',
    component: () => import('@/views/admin/system/notification_templates/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.notification_templates',
      breadcrumb: [
        {
          text: 'market.navigation.notification_templates',
          to: { name: 'admin:notification_templates-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:settings',
      action: 'update',
    },
  },
  {
    path: '/admin/notification_templates/edit/:id',
    name: 'admin:notification_templates-update',
    component: () => import('@/views/admin/system/notification_templates/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.notification_templates',
      breadcrumb: [
        {
          text: 'market.navigation.notification_templates',
          to: { name: 'admin:notification_templates-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:settings',
      action: 'update',
    },
  },

  {
    path: '/admin/users',
    name: 'admin:users-index',
    component: () => import('@/views/admin/users/List.vue'),
    meta: {
      pageTitle: 'market.navigation.users',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'market.navigation.users_list',
          active: true,
        },
      ],
      resource: 'admin:users',
      action: 'index',
    },
  },
  {
    path: '/admin/users/create',
    name: 'admin:users-store',
    component: () => import('@/views/admin/users/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.users',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'admin:users-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:users',
      action: 'store',
    },
  },
  {
    path: '/admin/users/edit/:id',
    name: 'admin:users-update',
    component: () => import('@/views/admin/users/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.users',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'admin:users-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:users',
      action: 'update',
    },
  },

  {
    path: '/admin/roles',
    name: 'admin:roles-index',
    component: () => import('@/views/admin/roles/List.vue'),
    meta: {
      pageTitle: 'market.navigation.roles',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'admin:users-index' },
        },
        {
          text: 'market.navigation.roles',
          active: true,
        },
      ],
      resource: 'admin:roles',
      action: 'index',
    },
  },
  {
    path: '/admin/roles/create',
    name: 'admin:roles-store',
    component: () => import('@/views/admin/roles/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.roles',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'admin:users-index' },
        },
        {
          text: 'market.navigation.roles',
          to: { name: 'admin:roles-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:roles',
      action: 'store',
    },
  },
  {
    path: '/admin/roles/edit/:id',
    name: 'admin:roles-update',
    component: () => import('@/views/admin/roles/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.roles',
      breadcrumb: [
        {
          text: 'market.navigation.users',
          to: { name: 'admin:users-index' },
        },
        {
          text: 'market.navigation.roles',
          to: { name: 'admin:roles-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:roles',
      action: 'update',
    },
  },

  {
    path: '/admin/faq',
    name: 'admin:faq-index',
    component: () => import('@/views/admin/faq/List.vue'),
    meta: {
      pageTitle: 'market.navigation.faq',
      breadcrumb: [
        {
          text: 'market.navigation.faq',
          active: true,
        },
      ],
      resource: 'admin:faq',
      action: 'index',
    },
  },
  {
    path: '/admin/faq/create',
    name: 'admin:faq-store',
    component: () => import('@/views/admin/faq/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.faq',
      breadcrumb: [
        {
          text: 'market.navigation.faq',
          to: { name: 'admin:faq-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:faq',
      action: 'store',
    },
  },
  {
    path: '/admin/faq/edit/:id',
    name: 'admin:faq-update',
    component: () => import('@/views/admin/faq/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.faq',
      breadcrumb: [
        {
          text: 'market.navigation.faq',
          to: { name: 'admin:faq-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:faq',
      action: 'update',
    },
  },

  {
    path: '/admin/faq-categories',
    name: 'admin:faq-categories-index',
    component: () => import('@/views/admin/faq/categories/List.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'index',
    },
  },
  {
    path: '/admin/faq-categories/create',
    name: 'admin:faq-categories-store',
    component: () => import('@/views/admin/faq/categories/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:faq-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'store',
    },
  },
  {
    path: '/admin/faq-categories/edit/:id',
    name: 'admin:faq-categories-update',
    component: () => import('@/views/admin/faq/categories/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.categories',
      breadcrumb: [
        {
          text: 'market.navigation.categories',
          to: { name: 'admin:post-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:categories',
      action: 'update',
    },
  },

  {
    path: '/admin/templates',
    name: 'admin:templates-index',
    component: () => import('@/views/admin/templates/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.templates',
      breadcrumb: [
        {
          text: 'market.navigation.admin.templates',
          active: true,
        },
      ],
      resource: 'admin:templates',
      action: 'index',
    },
  },
  {
    path: '/admin/templates/create',
    name: 'admin:templates-store',
    component: () => import('@/views/admin/templates/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.templates',
      breadcrumb: [
        {
          text: 'market.navigation.admin.templates',
          to: { name: 'admin:templates-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:templates',
      action: 'store',
    },
  },
  {
    path: '/admin/templates/edit/:id',
    name: 'admin:templates-update',
    component: () => import('@/views/admin/templates/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.templates',
      breadcrumb: [
        {
          text: 'market.navigation.admin.templates',
          to: { name: 'admin:templates-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:templates',
      action: 'update',
    },
  },

  {
    path: '/admin/transactions',
    name: 'admin:transactions-index',
    component: () => import('@/views/admin/transactions/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.transactions',
      breadcrumb: [
        {
          text: 'market.navigation.admin.transactions',
          active: true,
        },
      ],
      resource: 'admin:transactions',
      action: 'index',
    },
  },

  {
    path: '/admin/pages',
    name: 'admin:pages-index',
    component: () => import('@/views/admin/pages/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.pages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.pages',
          active: true,
        },
      ],
      resource: 'admin:pages',
      action: 'index',
    },
  },
  {
    path: '/admin/pages/create',
    name: 'admin:pages-store',
    component: () => import('@/views/admin/pages/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.pages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.pages',
          to: { name: 'admin:pages-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:pages',
      action: 'store',
    },
  },
  {
    path: '/admin/pages/edit/:id',
    name: 'admin:pages-update',
    component: () => import('@/views/admin/pages/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.pages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.pages',
          to: { name: 'admin:pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:pages',
      action: 'update',
    },
  },

  {
    path: '/admin/projects',
    name: 'admin:projects-index',
    component: () => import('@/views/admin/projects/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.projects',
      breadcrumb: [
        {
          text: 'market.navigation.admin.projects',
          active: true,
        },
      ],
      resource: 'admin:projects',
      action: 'index',
    },
  },
  {
    path: '/admin/tasks',
    name: 'admin:tasks-index',
    component: () => import('@/views/admin/tasks/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.tasks',
      breadcrumb: [
        {
          text: 'market.navigation.admin.tasks',
          active: true,
        },
      ],
      resource: 'admin:projects',
      action: 'index',
    },
  },

  {
    path: '/admin/messages',
    name: 'admin:messages-index',
    component: () => import('@/views/admin/messages/List.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.messages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.messages',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'index',
    },
  },
  {
    path: '/admin/messages/create',
    name: 'admin:messages-store',
    component: () => import('@/views/admin/messages/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.messages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.messages',
          to: { name: 'admin:messages-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'show',
    },
  },
  {
    path: '/admin/messages/edit/:id',
    name: 'admin:messages-update',
    component: () => import('@/views/admin/messages/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.admin.messages',
      breadcrumb: [
        {
          text: 'market.navigation.admin.messages',
          to: { name: 'admin:messages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'admin:reviews',
      action: 'show',
    },
  },
  {
    path: '/admin/account/projects',
    name: 'admin:admin-projects-index',
    component: () => import('@/views/admin/account/projects/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.my_projects',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
      ],
      resource: 'admin:projects',
      action: 'index',
    },
  },
  {
    path: '/admin/account/projects/start/:id',
    name: 'admin:admin-projects-start',
    component: () => import('@/views/admin/account/projects/Start.vue'),
    meta: {
      pageTitle: 'market.navigation.my_projects',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          to: { name: 'admin:admin-projects-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'admin:projects',
      action: 'store',
    },
  },
  {
    path: '/admin/account/projects/details/:id',
    name: 'admin:admin-projects-details',
    component: () => import('@/views/admin/account/projects/tasks/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.my_projects',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          to: { name: 'admin:admin-projects-index' },
        },
        {
          text: 'project.list.label.show',
          active: true,
        },
      ],
      resource: 'admin:projects',
      action: 'update',
    },
  },
]
