import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import dashboard from './routes/dashboard'
import account from './routes/account'
import authentication from './routes/authentication'
import others from './routes/others'
// admin
import admin from './routes/admin'
// client
import client from './routes/client'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...authentication,
    ...dashboard,
    ...account,
    ...others,
    ...admin,
    ...client,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.query && to.query.tg_auth) {
    if (to.query.r) {
      const refUser = to.query.r.split(':')[0]
      Vue.$cookies.set('ref', refUser)
    }
    const tgQuery = to.query.tg_auth.split(':')

    useJwt.login({
      tg: tgQuery[0],
      code: tgQuery[1],
      ref: Vue.$cookies.get('ref'),
    })
      .then(response => {
        const {
          data, token, localization,
        } = response.data

        useJwt.setToken(token)
        // useJwt.setRefreshToken(response.data.refreshToken)
        localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(data))
        localStorage.setItem('localization', JSON.stringify(localization))

        // Vue.$ability.update(data.permissions)
        // next(getHomeRouteForLoggedInUser())
        window.location.href = process.env.VUE_APP_APP_URL
      })
  }

  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to) && to.name !== 'home') {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  if (to.query && to.query.r) {
    const refQuery = to.query.r.split(':')
    const refUser = refQuery[0]
    Vue.$cookies.set('ref', refUser)

    const refRoute = refQuery[1] ? refQuery[1] : null
    if (refRoute && refRoute.length) {
      if (refRoute === 'c') {
        return next({ name: 'products-index' })
      }
      if (refRoute === 'h') {
        return next({ name: 'products-hot' })
      }
      // eslint-disable-next-line radix
      if (parseInt(refRoute)) {
        return next({ name: 'products-show', params: { slug: refRoute } })
      }
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
