import Vue from 'vue'
import _ from 'lodash'

Vue.filter('firstLetterAvatar', input => {
  let newLines = ''
  if (input) {
    const lines = input.match(/[^\s]+/g)
    if (lines !== null) {
      for (let i = 0; i < lines.length; i += 1) {
        if (i < 2) {
          newLines += lines[i].charAt(0).toUpperCase()
        }
      }
    }
  }
  return newLines
})

Vue.filter('hideEmail', input => {
  let newLines = ''
  if (input) {
    for (let i = 0; i < input.length; i += 1) {
      if (i > 2 && i < 9) {
        newLines += '**'
      } else {
        newLines += input[i].charAt(0)
      }
    }
  }
  return newLines
})

Vue.filter('transformForVSelect', (data, value, label) => data.map(item => ({
  label: item[label],
  value: item[value],
})))

Vue.filter('transformForBSelect', (data, value, text) => data.map(item => ({
  text: item[text],
  value: item[value],
})))

Vue.filter('preparePermissions', data => {
  const permissions = {}

  _.each(data, permission => {
    _.each(permission, (value, key) => {
      if (key !== 'module') {
        permissions[`${permission.module}-${key}`] = value
      }
    })
  })

  return permissions
})

Vue.filter('moneyFormat', data => new Intl.NumberFormat('uk-UA', {
  style: 'currency',
  currency: 'UAH',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format(data))

Vue.filter('numberFormat', data => new Intl.NumberFormat('uk-UA', { style: 'decimal' }).format(Math.round(data)))

// eslint-disable-next-line no-nested-ternary
Vue.filter('fFormat', num => (num > 999999 ? `${(num / 1000999).toFixed(1)}m` : (num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toFixed(0))))

Vue.filter('full_freeze', data => {
  if (!Object.keys(data).length) {
    return data
  }

  return _.reduce(data, (result, value, key) => {
    result[key] = Object.freeze(value)
    return result
  }, {})
})

Vue.filter('singleMedia', file => {
  if (file && file.media && file.media.id) {
    file.media.type = file.type
    file.media.format = file.media.url.split('.').pop().trim().toLowerCase()

    return file.media
  }

  return null
})

Vue.filter('multipleMedia', data => {
  const files = []

  if (!data) {
    return []
  }

  _.forEach(data, file => {
    const media = file.media && file.media.id ? file.media : null
    if (media) {
      media.type = file.type
      media.format = file.media.url.split('.').pop().trim().toLowerCase()

      files.push(media)
    }
  })

  return files
})

Vue.filter('mediaUrl', (item, field, size, locale) => {
  let image = {}
  if (locale && field) {
    image = item[field][locale]
  } else if (!locale && field) {
    image = item[field]
  } else {
    image = item
  }

  if (!image) {
    return '/icons/placeholder.jpg'
  }

  if (Object.prototype.hasOwnProperty.call(image, 'media')) {
    if (image.media.sizes && !_.isEmpty(image.media.sizes)) {
      return image.media.sizes[size].url ?? '/icons/placeholder.jpg'
    }
    if (!image.media.sizes || _.isEmpty(image.media.sizes)) {
      return image.media && image.media.url ? image.media.url : '/icons/placeholder.jpg'
    }
  }

  if (!Object.prototype.hasOwnProperty.call(image, 'media')) {
    if (image.sizes && !_.isEmpty(image.sizes)) {
      return image.sizes[size].url ?? '/icons/placeholder.jpg'
    }
  }

  if (Object.prototype.hasOwnProperty.call(image, 'format')) {
    if (image.format === 'mp4') {
      return '/icons/placeholderVideo.png'
    }
  }

  return image.url ? image.url : '/icons/placeholder.jpg'
})

Vue.filter('transl', (item, field, locale = 'uk') => item[field] ?? _.head(_.filter(item.translations, { locale }))[field])

Vue.filter('transliterate', word => {
  const a = {
    '(': '_', ')': '_', ',': '_', '.': '_', ' ': '_', Ё: 'YO', Й: 'I', Ц: 'TS', У: 'U', К: 'K', Е: 'E', Н: 'N', Г: 'G', Ш: 'SH', Щ: 'SCH', З: 'Z', Х: 'H', Ъ: '', ё: 'yo', й: 'i', ц: 'ts', у: 'u', к: 'k', е: 'e', н: 'n', г: 'g', ш: 'sh', щ: 'sch', з: 'z', х: 'h', ъ: '', Ф: 'F', Ы: 'I', В: 'V', А: 'a', П: 'P', Р: 'R', О: 'O', Л: 'L', Д: 'D', Ж: 'ZH', Э: 'E', ф: 'f', ы: 'i', в: 'v', а: 'a', п: 'p', р: 'r', о: 'o', л: 'l', д: 'd', ж: 'zh', э: 'e', Я: 'Ya', Ч: 'CH', С: 'S', М: 'M', И: 'I', Т: 'T', Ь: '', Б: 'B', Ю: 'YU', я: 'ya', ч: 'ch', с: 's', м: 'm', и: 'i', т: 't', ь: '', б: 'b', ю: 'yu',
  }
  return word.split('').map(char => a[char] || char).join('').toLowerCase()
})

Vue.filter('cleanText', html => {
  if (!html) return null
  const allowedTags = ['b', 'i', 's', 'u', 'strong', 'em', 'a', 'p', 'br']
  // const regex = new RegExp(`<\\/?(?!(${allowedTags.join('|')})\\b)[^>]*>`, 'gi')
  // const cleanedHtml = html.replace(regex, '')
  const regex = new RegExp(`<\\/?(?!(${allowedTags.join('|')})\\b)[^>]*>`, 'gi')
  const cleanedHtml = html.replace(regex, match => {
    const at = allowedTags.includes(match.replace(/<\/?([^\s>]*)[^>]*>/, '$1').toLowerCase())
    return at ? match : ''
  })
  const styleRegex = / style="[^"]*"/gi
  return cleanedHtml.replace(styleRegex, '')
})
