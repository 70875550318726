<template>
  <div class="fm-additions-file-list">
    <div
      v-for="(item, index) in selectedItems"
      :key="index"
      class="d-flex justify-content-between"
    >
      <div class="w-75 text-truncate">
        <span v-if="item.type === 'dir'">
          <i class="far fa-folder" />{{ item.basename }}
        </span>
        <span v-else>
          <i
            class="far"
            :class="extensionToIcon(item.extension)"
          /> {{ item.basename }}
        </span>
      </div>
      <div
        v-if="item.type === 'file'"
        class="text-right"
      >
        {{ bytesToHuman(item.size) }}
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../../../mixins/helper'

export default {
  name: 'SelectedFileList',
  mixins: [helper],
  computed: {
    /**
     * Selected files and folders
     * @returns {*}
     */
    selectedItems() {
      return this.$store.getters['fm/selectedItems']
    },
  },
}
</script>

<style lang="scss">
    .fm-additions-file-list {
        .far {
            padding-right: 0.5rem;
        }
    }
</style>
