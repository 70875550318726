<template>
  <div class="modal-content fm-modal-folder">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ lang.modal.newFolder.title }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        @click="hideModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="fm-folder-name">{{ lang.modal.newFolder.fieldName }}</label>
        <input
          id="fm-folder-name"
          v-model="directoryName"
          v-focus
          type="text"
          class="form-control"
          :class="{'is-invalid': directoryExist}"
          @keyup="validateDirName"
        >
        <div
          v-show="directoryExist"
          class="invalid-feedback"
        >
          {{ lang.modal.newFolder.fieldFeedback }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-info"
        :disabled="!submitActive"
        @click="addFolder"
      >{{ lang.btn.submit }}
      </button>
      <button
        class="btn btn-light"
        @click="hideModal"
      >
        {{ lang.btn.cancel }}
      </button>
    </div>
  </div>
</template>

<script>
import modal from '../mixins/modal'
import translate from '../../../mixins/translate'

export default {
  name: 'NewFolder',
  mixins: [modal, translate],
  data() {
    return {
      // name for new directory
      directoryName: '',

      // directory exist
      directoryExist: false,
    }
  },
  computed: {
    /**
     * Submit button - active or no
     * @returns {string|boolean}
     */
    submitActive() {
      return this.directoryName && !this.directoryExist
    },
  },
  methods: {
    /**
     * Check the folder name if it exists or not.
     */
    validateDirName() {
      if (this.directoryName) {
        this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](this.directoryName)
      } else {
        this.directoryExist = false
      }
    },

    /**
     * Create new directory
     */
    addFolder() {
      this.$store.dispatch('fm/createDirectory', this.directoryName).then(response => {
        // if new directory created successfully
        if (response.data.result.status === 'success') {
          // close modal window
          this.hideModal()
        }
      })
    },
  },
}
</script>
