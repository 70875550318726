<template>
  <div class="modal-content fm-modal-unzip">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ lang.modal.unzip.title }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        @click="hideModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-between">
        <div>
          <strong>{{ lang.modal.unzip.fieldRadioName }}</strong>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="unzipRadio1"
            v-model.number="createFolder"
            class="form-check-input"
            type="radio"
            name="uploadOptions"
            value="0"
            checked
          >
          <label
            class="form-check-label"
            for="unzipRadio1"
          >
            {{ lang.modal.unzip.fieldRadio1 }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="unzipRadio2"
            v-model.number="createFolder"
            class="form-check-input"
            type="radio"
            name="uploadOptions"
            value="1"
            checked
          >
          <label
            class="form-check-label"
            for="unzipRadio2"
          >
            {{ lang.modal.unzip.fieldRadio2 }}
          </label>
        </div>
      </div>
      <hr>
      <div
        v-if="createFolder"
        class="form-group"
      >
        <label for="fm-folder-name">{{ lang.modal.unzip.fieldName }}</label>
        <input
          id="fm-folder-name"
          v-model="directoryName"
          v-focus
          type="text"
          class="form-control"
          :class="{'is-invalid': directoryExist}"
          @keyup="validateDirName"
        >
        <div
          v-show="directoryExist"
          class="invalid-feedback"
        >
          {{ lang.modal.unzip.fieldFeedback }}
        </div>
      </div>
      <span
        v-else
        class="text-danger"
      >{{ lang.modal.unzip.warning }}</span>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-info"
        :disabled="!submitActive"
        @click="unpackArchive"
      >{{ lang.btn.submit }}
      </button>
      <button
        class="btn btn-light"
        @click="hideModal"
      >
        {{ lang.btn.cancel }}
      </button>
    </div>
  </div>
</template>

<script>
import modal from '../mixins/modal'
import translate from '../../../mixins/translate'

export default {
  name: 'Unzip',
  mixins: [modal, translate],
  data() {
    return {
      createFolder: 0,

      // name for new directory
      directoryName: '',

      // directory exist
      directoryExist: false,
    }
  },
  computed: {
    /**
     * Submit button - active or no
     * @returns {string|boolean}
     */
    submitActive() {
      if (this.createFolder) {
        return this.directoryName && !this.directoryExist
      }

      return true
    },
  },
  methods: {
    /**
     * Check the folder name if it exists or not.
     */
    validateDirName() {
      if (this.directoryName) {
        this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](this.directoryName)
      } else {
        this.directoryExist = false
      }
    },

    /**
     * Unpack selected archive
     */
    unpackArchive() {
      this.$store.dispatch('fm/unzip', this.createFolder ? this.directoryName : null).then(() => {
        // close modal window
        this.hideModal()
      })
    },
  },
}
</script>
