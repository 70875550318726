export default [
  {
    path: '/account/setting',
    name: 'account:account-setting',
    component: () => import('@/views/account/setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'market.navigation.settings',
      breadcrumb: [
        {
          text: 'market.navigation.system',
        },
        {
          text: 'market.navigation.settings',
          active: true,
        },
      ],
      resource: 'account',
      action: 'index',
    },
  },

  {
    path: '/account/channels',
    name: 'account:products-index',
    component: () => import('@/views/account/channels/List.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
        {
          text: 'market.navigation.channels',
          active: true,
        },
      ],
      resource: 'products',
      action: 'index',
    },
  },
  {
    path: '/account/channels/item/:slug',
    name: 'account:products-show',
    component: () => import('@/views/account/channels/View.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
        {
          text: 'market.navigation.channels',
          to: { name: 'account:products-index' },
        },
      ],
      resource: 'products',
      action: 'show',
    },
  },
  {
    path: '/account/channels/edit/:slug',
    name: 'account:products-update',
    component: () => import('@/views/account/channels/Edit.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
        {
          text: 'market.navigation.channels',
          to: { name: 'account:products-index' },
        },
      ],
      resource: 'products',
      action: 'update',
    },
  },
  {
    path: '/account/channels/create',
    name: 'account:products-store',
    component: () => import('@/views/account/channels/Create.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
        {
          text: 'market.navigation.channels',
          to: { name: 'account:products-index' },
        },
      ],
      resource: 'products',
      action: 'store',
    },
  },

  {
    path: '/account/templates',
    name: 'account:templates-index',
    component: () => import('@/views/account/templates/List.vue'),
    meta: {
      pageTitle: 'market.navigation.my_templates',
      breadcrumb: [
        {
          text: 'market.navigation.my_templates',
          active: true,
        },
      ],
      resource: 'templates',
      action: 'index',
    },
  },
  {
    path: '/account/templates/create',
    name: 'account:templates-store',
    component: () => import('@/views/account/templates/Create.vue'),
    meta: {
      pageTitle: 'market.navigation.my_templates',
      breadcrumb: [
        {
          text: 'market.navigation.my_templates',
          to: { name: 'account:templates-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'templates',
      action: 'store',
    },
  },
  {
    path: '/account/templates/edit/:id',
    name: 'account:templates-update',
    component: () => import('@/views/account/templates/Edit.vue'),
    meta: {
      pageTitle: 'market.navigation.my_templates',
      breadcrumb: [
        {
          text: 'market.navigation.my_templates',
          to: { name: 'account:templates-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'templates',
      action: 'update',
    },
  },

  {
    path: '/account/projects',
    name: 'account:projects-index',
    component: () => import('@/views/account/projects/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.my_projects',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          active: true,
        },
      ],
      resource: 'projects',
      action: 'index',
    },
  },
  {
    path: '/account/projects/start/:id',
    name: 'account:projects-start',
    component: () => import('@/views/account/projects/Start.vue'),
    meta: {
      pageTitle: 'market.navigation.my_projects',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          to: { name: 'account:projects-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'projects',
      action: 'store',
    },
  },
  {
    path: '/account/projects/details/:id',
    name: 'account:projects-details',
    component: () => import('@/views/account/projects/tasks/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.my_projects',
      breadcrumb: [
        {
          text: 'market.navigation.my_projects',
          to: { name: 'account:projects-index' },
        },
        {
          text: 'project.list.label.show',
          active: true,
        },
      ],
      resource: 'projects',
      action: 'update',
    },
  },

  {
    path: '/account/tasks',
    name: 'account:tasks-index',
    component: () => import('@/views/account/tasks/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.my_tasks',
      breadcrumb: [
        {
          text: 'market.navigation.my_tasks',
          active: true,
        },
      ],
      resource: 'projects',
      action: 'index',
    },
  },
]
