import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'
import infiniteScroll from 'vue-infinite-scroll'
import VueGtm from '@gtm-support/vue2-gtm'
import VueMeta from 'vue-meta'

import i18n from '@/libs/i18n'
import FileManager from '@vendor/filemanager/init'
import router from './router'
import store from './store'
import App from './App.vue'

// Filters
import './filters/index'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(infiniteScroll)
Vue.use(VueCookies, { expire: '7d' })

Vue.use(FileManager, { store })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueGtm, {
  id: 'GTM-PNP2N3TQ',
  defer: false,
  compatibility: false,
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [
    'admin:account-setting',
    'admin:products-index',
    'admin:products-store',
    'admin:products-update',
    'admin:stickers-index',
    'admin:stickers-store',
    'admin:stickers-update',
    'admin:product-categories-index',
    'admin:product-categories-store',
    'admin:product-categories-update',
    'admin:reviews-index',
    'admin:reviews-store',
    'admin:reviews-update',
    'admin:posts-index',
    'admin:posts-store',
    'admin:posts-update',
    'admin:post-categories-index',
    'admin:post-categories-store',
    'admin:post-categories-update',
    'admin:navigations-index',
    'admin:navigations-store',
    'admin:navigations-update',
    'admin:navigation-item-index',
    'admin:navigation-item-store',
    'admin:navigation-item-update',
    'admin:settings',
    'admin:languages',
    'admin:translations',
    'admin:notification_templates-index',
    'admin:notification_templates-store',
    'admin:notification_templates-update',
    'admin:users-index',
    'admin:users-store',
    'admin:users-update',
    'admin:roles-index',
    'admin:roles-store',
    'admin:roles-update',
    'admin:faq-index',
    'admin:faq-store',
    'admin:faq-update',
    'admin:faq-categories-index',
    'admin:faq-categories-store',
    'admin:faq-categories-update',
    'admin:templates-index',
    'admin:templates-store',
    'admin:templates-update',
    'admin:transactions-index',
    'admin:transactions-store',
    'admin:transactions-update',
    'admin:pages-index',
    'admin:pages-store',
    'admin:pages-update',
    'admin:projects-index',
    'admin:projects-store',
    'admin:projects-update',
    'admin:messages-index',
    'admin:messages-store',
    'admin:messages-update',
  ],
  trackOnNextTick: false,
})

Vue.use(VueMeta)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
