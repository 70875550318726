export default [
  {
    path: '/catalog/channels',
    name: 'products-index',
    component: () => import('@/views/catalog/channels/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.catalog.title',
      pageTitleTag: 'h1',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          active: true,
        },
      ],
      resource: 'Auth',
    },
  },
  {
    path: '/catalog/hot_channels',
    name: 'products-hot',
    component: () => import('@/views/catalog/hot/List.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'market.hot_channels.title',
      pageTitleTag: 'h1',
      breadcrumb: [
        {
          text: 'market.navigation.hot_channels',
          active: true,
        },
      ],
      resource: 'Auth',
    },
  },
  {
    path: '/catalog/channels/:slug',
    name: 'products-show',
    component: () => import('@/views/catalog/channels/View.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'market.navigation.channels',
      breadcrumb: [
        {
          text: 'market.navigation.channels',
          to: { name: 'products-index' },
        },
      ],
      resource: 'Auth',
    },
  },

  {
    path: '/blog',
    name: 'posts-index',
    component: () => import('@/views/blog/List.vue'),
    meta: {
      pageTitle: 'market.navigation.blog',
      pageTitleTag: 'h1',
      breadcrumb: [
        {
          text: 'market.navigation.blog',
          active: true,
        },
      ],
      resource: 'Auth',
    },
  },
  {
    path: '/blog/:slug',
    name: 'posts-show',
    component: () => import('@/views/blog/View.vue'),
    meta: {
      pageTitle: 'market.navigation.blog',
      breadcrumb: [
        {
          text: 'market.navigation.blog',
          to: { name: 'posts-index' },
        },
      ],
      resource: 'Auth',
    },
  },

  {
    path: '/faq',
    name: 'faq-index',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      pageTitle: 'market.navigation.faq',
      pageTitleTag: 'h1',
      breadcrumb: [
        {
          text: 'market.navigation.faq',
          active: true,
        },
      ],
      resource: 'Auth',
    },
  },

  {
    path: '/packages',
    name: 'packages-index',
    component: () => import('@/views/packages/Packages.vue'),
    meta: {
      resource: 'Auth',
    },
  },

  {
    path: '/telegram-ads',
    name: 'telegramads-index',
    component: () => import('@/views/info/Telegramads.vue'),
    meta: {
      resource: 'Auth',
    },
  },

  {
    path: '/checkout',
    name: 'checkout-index',
    component: () => import('@/views/checkout/Checkout.vue'),
    meta: {
      pageTitle: 'cart.page.titles.title',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'cart.page.titles.breadcrumb',
        },
        {
          text: 'cart.page.titles.title',
          active: true,
        },
      ],
      resource: 'Auth',
    },
  },

  {
    path: '/info/:slug',
    name: 'pages-show',
    component: () => import('@/views/info/View.vue'),
    meta: {
      contentClass: 'page_wrap',
      pageTitle: 'market.navigation.blog',
      resource: 'Auth',
    },
  },
]
